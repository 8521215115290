<template>
  <external-app-layout></external-app-layout>
</template>

<script>
export default {
  name: "DocumentHome",
  components: {
    ExternalAppLayout: () => import("@/views/shared/layout/ExternalAppLayout")
  }
};
</script>
